import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { auth } from '@/firebase';
import Loader from '@components/Loader';
import AppRoutes from '@/routes/AppRoutes';
import BottomNavBar from '@components/BottomNavBar';
import { setUser, clearUser } from '@store/actions/authActions';
import { selectUser } from '@store/selectors/authSelectors';

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(true);

  // Check if the user is authenticated
  useEffect(() => {
    // Monitor authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        dispatch(setUser(currentUser));
      } else {
        dispatch(clearUser());
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App fixed inset-0 bg-white overflow-auto p-4 max-w-xl mx-auto md:border ">
      <Suspense fallback={<Loader />}>
        <AppRoutes />
        {user && <BottomNavBar />}
      </Suspense>
    </div>
  );
};

export default App;
