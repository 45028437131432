import { configureStore } from '@reduxjs/toolkit';
import authReducer from '@store/reducers/authReducer';
import petReducer from '@store/reducers/petReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    pets: petReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
