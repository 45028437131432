import { createSlice } from '@reduxjs/toolkit';
import { addPetThunk, fetchPetsThunk, fetchPetByIdThunk, updatePetThunk, deletePetThunk } from '@store/actions/petActions';

const initialState = {
  list: [],
  pet: null,
  status: 'idle',
  error: null,
};

const petSlice = createSlice({
  name: 'pets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPetsThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPetsThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(fetchPetsThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchPetByIdThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pet = action.payload;
      })
      .addCase(addPetThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addPetThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list.push(action.payload);
      })
      .addCase(addPetThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updatePetThunk.fulfilled, (state, action) => {
        const index = state.list.findIndex((pet) => pet.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = { ...state.list[index], ...action.payload };
        }
      })
      .addCase(deletePetThunk.fulfilled, (state, action) => {
        state.list = state.list.filter((pet) => pet.id !== action.payload);
      });
  },
});

export default petSlice.reducer;
