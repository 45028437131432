// src/store/reducers/authReducer.js
import { createReducer } from '@reduxjs/toolkit';
import { setUser, clearUser } from '@store/actions/authActions';

// Initial state
const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
};

// Create the reducer
const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUser, (state, action) => {
      state.user = action.payload;
    })
    .addCase(clearUser, (state) => {
      state.user = null;
    });
});

export default authReducer;
