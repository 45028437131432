import { collection, query, where, getDoc, getDocs, updateDoc, doc, or, and, addDoc } from 'firebase/firestore';

import { db } from '@/firebase';
import { generatePetId, uploadPetImage } from '@utils/helpers';

const petsCollection = collection(db, 'pets');

export const getPets = async (userId) => {
  const q = query(
    petsCollection,
    and(
      where('isDeleted', '==', false),
      or(where('sharedUserIds', 'array-contains', userId), where('userId', '==', userId))
    )
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getPetById = async (id) => {
  const docRef = doc(db, 'pets', id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return { id, ...docSnap.data() };
  } else {
    throw new Error('Pet not found');
  }
};

export const addPet = async (petData) => {
  let imageUrl = '';
      if (petData.imageFile) {
        imageUrl = await uploadPetImage(petData.imageFile);
      }

      // generate a unique id using nanoid for the pet with patter of 4 alphabets followed by 3 numbers and 1 alphabet
      const petId = await generatePetId();

      const data = {
        userId: petData.userId,
        sharedUserIds: [],
        weight: petData.weight,
        species: petData.species,
        breed: petData.breed,
        givenName: petData.givenName,
        microchipID: petData?.microchipID || '',
        dob: petData.dob,
        gender: petData.gender,
        isNeutered: petData?.isNeutered || false,
        isInsured: petData?.isInsured || false,
        colorOrMarkings: petData?.colorOrMarkings || '',
        imageUrl,
        createdAt: new Date(),
        isDeleted: false,
        petId,
      };

      // Add pet to Firestore
      const docRef = await addDoc(collection(db, 'pets'), data);

    return { ...data, id: docRef.id };
};

export const updatePet = async (pet) => {
  const { id, ...data } = pet;
  const docRef = doc(db, 'pets', id);
  await updateDoc(docRef, data);
  return pet;
};

export const deletePet = async (id) => {
  const petRef = doc(db, 'pets', id);
  await updateDoc(petRef, { isDeleted: true });
  return id;
}
