import { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import Login from '@pages/Login';
import Home from '@pages/HomePage';
const AddPet = lazy(() => import('@pages/AddPet'));
const EditPet = lazy(() => import('@pages/EditPet'));
const PetDetails = lazy(() => import('@pages/PetDetails'));
const UserProfile = lazy(() => import('@pages/UserProfile'));

const AppRoutes = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <Routes>
      {/* If user is logged in, go to home page, else redirect to login */}
      <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
      <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
      <Route path="/profile" element={user ? <UserProfile /> : <Navigate to="/login" />} />
      <Route path="/add-pet" element={user ? <AddPet /> : <Navigate to="/login" />} />
      <Route path="/pet/:id" element={user ? <PetDetails /> : <Navigate to="/login" />} />
      <Route path="/pet/:id/edit" element={user ? <EditPet /> : <Navigate to="/login" />} />
    </Routes>
  );
};

export default AppRoutes;
