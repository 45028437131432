import { v4 as uuidv4 } from 'uuid';
import { customAlphabet } from 'nanoid';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, query, where, getDocs } from 'firebase/firestore';

import { db, storage } from '@/firebase';

// Nano ID custom generators
const generateAlphabets = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 4); // First 4 alphabets
const generateDigits = customAlphabet('0123456789', 3); // Next 3 digits
const generateLastAlphabet = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 1);

const generatePetId = async () => {
  let petId;

  do {
    const alphabet = generateAlphabets();
    const numbers = generateDigits();
    const lastAlphabet = generateLastAlphabet();

    petId = `${alphabet}${numbers}${lastAlphabet}`;
  } while (await checkIfPetIdExists(petId));

  return petId;
};

const checkIfPetIdExists = async (petId) => {
  const petsCollection = collection(db, 'pets');
  const q = query(petsCollection, where('petId', '==', petId));
  const querySnapshot = await getDocs(q);

  return !querySnapshot.empty;
};

const uploadPetImage = async (imageFile) => {
  const imageRef = ref(storage, `pets/${uuidv4()}`);
  await uploadBytes(imageRef, imageFile);
  return await getDownloadURL(imageRef);
};

export { generatePetId, uploadPetImage };
