// src/store/actions/authActions.js
import { signOut } from 'firebase/auth';
import { createAction } from '@reduxjs/toolkit';

import { auth } from '@/firebase';
// Action to set user
export const setUser = createAction('auth/setUser', (user) => {
  // This payload creator can handle saving to localStorage as a side effect
  localStorage.setItem('user', JSON.stringify(user));
  return {
    payload: user,
  };
});

// Action to clear user
export const clearUser = createAction('auth/clearUser', async () => {
  localStorage.removeItem('user');
  // Sign out of Firebase
  await signOut(auth);
  return {
    payload: null,
  };
});
