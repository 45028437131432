import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPets, getPetById, addPet, updatePet, deletePet } from '@services/petService';

export const fetchPetsThunk = createAsyncThunk('pets/fetchPets', async (userId, { getState }) => {
  const { pets } = getState();
  // Avoid fetching if pets are already loaded
  if (pets.list.length > 0) return pets.list;

  try {
    return await getPets(userId);
  } catch (error) {
    throw Error('Error fetching pets: ' + error.message);
  }
});

export const fetchPetByIdThunk = createAsyncThunk('pets/fetchPetById', async (id, { rejectWithValue }) => {
  try {
    return await getPetById(id);
  } catch (error) {
    console.error('Error fetching pet:', error);
    return rejectWithValue(error.message);
  }
});

export const addPetThunk = createAsyncThunk(
  'pets/addPet',
  async (petData, { getState, rejectWithValue }) => {
    try {
      const user = getState().auth.user;
      if (!user) {
        throw new Error('User not authenticated');
      }

      petData.userId = user.uid;

      return await addPet(petData);
    } catch (error) {
      console.error('Error adding pet:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const updatePetThunk = createAsyncThunk(
  'pets/updatePet',
  async (updatedPetData, { rejectWithValue }) => {
    try {
      return await updatePet(updatedPetData);
    } catch (error) {
      console.error('Error updating pet:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const deletePetThunk = createAsyncThunk('pets/deletePet', async (petId, { rejectWithValue }) => {
  try {
    return await deletePet(petId);
  } catch (error) {
    console.error('Error deleting pet:', error);
    return rejectWithValue(error.message);
  }
});
