// Select all pets
export const selectAllPets = (state) => state.pets.list;

// Select single pet by ID
export const selectPetById = (state, id) => state.pets.list.find((pet) => pet.id === id);

// Select the status of pets (loading, succeeded, etc.)
export const selectPetsStatus = (state) => state.pets.status;

// Select the error message (if any)
export const selectPetsError = (state) => state.pets.error;