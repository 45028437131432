import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPetsThunk } from '@store/actions/petActions';
import { selectAllPets } from '@store/selectors/petSelectors';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pets = useSelector(selectAllPets);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user && pets.length === 0) {
      dispatch(fetchPetsThunk(user.uid));
    }
  }, [user, pets.length, dispatch]);

  return (
    <div>
      {/* Top Banner with Scrollable Quotes */}
      <div className="mb-6">
        <div className="w-full h-40 bg-gradient-to-br from-dw-pink to-dw-light-pink rounded-lg shadow-md overflow-hidden">
          <div className="h-full w-full flex items-center justify-center animate-scroll text-dw-black text-md p-3 backdrop-blur-sm bg-white/30">
            &quot;Pets are not our whole life, but they make our lives whole.&quot; &mdash; Roger Caras
          </div>
        </div>
      </div>

      {/* List of Pets */}
      <h2 className="text-2xl font-semibold mb-6">Pets</h2>
      <div className="grid grid-cols-3 sm:grid-cols-3 gap-4 sm:gap-6">
        {pets.map((pet) => (
          <div key={pet.id} className="cursor-pointer" onClick={() => navigate(`/pet/${pet.id}`)}>
            {pet.imageUrl && (
              <img
                src={pet.imageUrl}
                alt={`${pet.givenName}'s Photo`}
                loading="lazy"
                className="w-24 h-24 sm:w-32 sm:h-32 object-cover rounded-full border-4 border-dw-pink mx-auto mb-2 shadow-sm hover:shadow-lg transition-shadow duration-300"
              />
            )}
            <h3 className="text-center text-sm sm:text-lg">{pet.givenName}</h3>
          </div>
        ))}
        {/* Add New Pet Placeholder */}
        <div className="cursor-pointer" onClick={() => navigate('/add-pet')}>
          <div className="flex flex-col items-center justify-center w-24 h-24 sm:w-32 sm:h-32 mb-2 mx-auto border-4 border-dashed border-gray-400 rounded-full">
            <span className="text-3xl sm:text-4xl text-gray-400">+</span>
          </div>
          <h3 className="text-center text-sm sm:text-lg text-gray-400">Add Pet</h3>
        </div>
      </div>
    </div>
  );
};

export default Home;
