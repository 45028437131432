import { Link, useLocation } from 'react-router-dom';
import { PiPawPrint } from 'react-icons/pi';
import { AiOutlineHome } from 'react-icons/ai';
import { HiOutlineFaceSmile } from 'react-icons/hi2';

const BottomNavBar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  // List of routes where the BottomNavBar should be displayed
  const allowedRoutes = ['/', '/profile'];

  // Check if the current route is in the allowed routes list
  if (!allowedRoutes.includes(currentPath)) {
    return null; // Do not render the BottomNavBar if the route is not in the list
  }

  return (
    <div className="fixed bottom-0 inset-x-0 w-full max-w-xl mx-auto bg-white shadow-lg border-t border-gray-200">
      <div className="flex justify-around items-center py-3">
        <Link
          to="/"
          aria-label="Home"
          title="Home"
          className={`flex flex-col items-center transition-colors duration-300 ${currentPath === '/' ? 'text-dw-black' : 'text-dw-grey'} hover:text-dw-black`}
        >
          <AiOutlineHome size={24} />
          <span className="text-sm">Home</span>
        </Link>
        <Link
          to="/add-pet"
          aria-label="Add Pet"
          title="Add Pet"
          className={`flex flex-col items-center transition-colors duration-300 ${currentPath === '/add-pet' ? 'text-dw-black' : 'text-dw-grey'} hover:text-dw-black`}
        >
          <PiPawPrint size={24} />
          <span className="text-sm">Add Pet</span>
        </Link>
        <Link
          to="/profile"
          aria-label="Profile"
          title="Profile"
          className={`flex flex-col items-center transition-colors duration-300 ${currentPath === '/profile' ? 'text-dw-black' : 'text-dw-grey'} hover:text-dw-black`}
        >
          <HiOutlineFaceSmile size={24} />
          <span className="text-sm">Profile</span>
        </Link>
      </div>
    </div>
  );
};

export default BottomNavBar;
